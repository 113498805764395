import { Container } from "react-bootstrap"

const ContactUs = () => {
  return (

    <Container className="d-flex justify-content-center">
        <p>
            <hr/>
             Hola! Para cualquier duda, escríbenos un correo a vanilladesigns.13@gmail.com y con gusto te apoyaremos. Nuestro horario de atención es de Lunes a Viernes de 10:00 am a 6:00 pm.
            <hr/>
        </p>
        
    </Container>
    
  )
}

export default ContactUs


      