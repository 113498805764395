import {useState} from 'react'
import {Form, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import FormContainer from '../components/FormContainer'
import {saveShippingAddress} from '../slices/cartSlice'
import CheckoutSteps from '../components/CheckoutSteps'

const ShippingScreen = () => {

    const cart = useSelector(state => state.cart)
    const {shippingAddress} = cart

    const [address, setAddress] = useState(shippingAddress?.address || '') 
    const [city, setCity] = useState(shippingAddress?.city || '')
    const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode || '')
    const [country, setCountry] = useState(shippingAddress?.country || '')

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(saveShippingAddress({address, city, postalCode, country}))
        navigate('/payment')
    }

    return (
        <FormContainer>
            <CheckoutSteps step1 step2/>
            <h1>Envío</h1>    

            <Form onSubmit={submitHandler}>
                <Form.Group controlId='address' className='my-2'>
                    <Form.Label>Domicilio </Form.Label>
                    <Form.Control 
                        type='text' 
                        placeholder='Ingresar domicilio' 
                        value={address} 
                        onChange={(e) => setAddress(e.target.value)} 
                    />
                </Form.Group>
                <Form.Group controlId='city' className='my-2'>
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control 
                        type='text' 
                        placeholder='Ingresar ciudad' 
                        value={city} 
                        onChange={(e) => setCity(e.target.value)} 
                    />
                </Form.Group>
                <Form.Group controlId='postalcode' className='my-2'>
                    <Form.Label>Código postal</Form.Label>
                    <Form.Control 
                        type='text' 
                        placeholder='Ingresar código postal' 
                        value={postalCode} 
                        onChange={(e) => setPostalCode(e.target.value)} 
                    />
                </Form.Group>
                <Form.Group controlId='country' className='my-2'>
                    <Form.Label>País</Form.Label>
                    <Form.Control 
                        type='text' 
                        placeholder='Ingresar país' 
                        value={country} 
                        onChange={(e) => setCountry(e.target.value)} 
                    />
                </Form.Group>

                <Button type='submit' variant='primary' className='my-2'>
                    Continuar 
                </Button>

            </Form>
            
        </FormContainer>
    )
}

export default ShippingScreen