import React, { useState } from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Rating from './Rating';
// import { BiSolidLeftArrow, BiSolidRightArrow} from "react-icons/bi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const Product = ({product}) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
    };

  
    return (
    <Card className="my-3 p-3 rounded">
        
            {/* <Card.Img src={`../${product.images[0]}`} variant='top'/> */}
            <div className="image-container">
            <Link to={`/product/${product._id}`}>
                    <Card.Img src={`../${product.images[currentImageIndex]}`} variant="top" />
                    </Link>
                    {product.images.length > 1 && (
                        <div className="image-controls">
                            <button className="arrow-btn " onClick={prevImage}>
                                <MdKeyboardArrowLeft />
                            </button>
                            <button className="arrow-btn " onClick={nextImage}>
                                <MdKeyboardArrowRight />
                            </button>
                        </div>
                    )}
            </div>
        

        <Card.Body>
            <Link to={`/product/${product._id}`}>
                <Card.Title as='div' className='product-title'>
                    <strong>{product.name}</strong>
                </Card.Title>
            </Link>

            <Card.Text as='div'>
                <Rating value={product.rating} text={`${product.numReviews} reviews`}/>
            </Card.Text>


            <Card.Text as='h3'>
                ${product.price} MXN
            </Card.Text>
        </Card.Body>
    </Card>
  )
}

export default Product