import {Row, Col} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import Product from '../components/Product';
import { useGetProductsQuery } from '../slices/productsApiSlice';
import Paginate from '../components/Paginate';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ProductCarousel from '../components/ProductCarousel';
import AboutMe from '../components/AboutMe';
import {Link} from 'react-router-dom';

const HomeScreen = () => {

  const { pageNumber, keyword } = useParams();
  const { data, isLoading, error} = useGetProductsQuery({keyword, pageNumber});  

  return (
    <>

      {!keyword ? (<ProductCarousel />) : (<Link to='/' className='btn btn-light mb-4'>Go Back</Link>)}

      {isLoading ? (
        <Loader/>
        
      ) : error ? (
        <Message variant='danger'>{error?.data?.message || error.error}</Message>
      ) : (<>
        <h1 className='mt-2 pt-2'></h1>
        <hr></hr>
        <Row>
            {data.products.map((product) => (
                <Col key={product._id} xs={6} sm={6} md={6} lg={4} xl={3}>
                    <Product product={product}/>
                </Col>
            ))}
        </Row>
        <Paginate 
          pages={data.pages} 
          page={data.page} 
          keyword={keyword ? keyword : ''}
          className=''
        />
      </>)}

    <hr className='mt-4 pt-2' />

    <AboutMe/>
      
    <hr className='mt-4'/>

    </>
  )
}

export default HomeScreen