import { Image, Container } from "react-bootstrap"


const AboutMe = () => {
  return (
    <Container className="d-flex justify-content-center">

        <Image 
            src='../Hola.jpg'
            alt='About Me'
            fluid
      
        />
    </Container>
  )
}

export default AboutMe