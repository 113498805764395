import {Container, Row, Col} from 'react-bootstrap'
import {FaInstagram, FaFacebook, FaTiktok} from 'react-icons/fa';
import {LinkContainer} from 'react-router-bootstrap';
import {Nav} from 'react-bootstrap';

const Footer = () => {

    const currentYear = new Date().getFullYear();

  return (
    <footer>
        <Container className='pb-5'>
            <Row>
                <Col className='' sm={12} lg={4}>
                    <p>Copyright &copy; {currentYear}, vanilla. </p>
                    <p>Organizacion consciente</p>
                </Col>
                <Col sm={12} lg={4}>

                    <LinkContainer to='/contactUs'>
                        <Nav.Link>
                            <p>Contáctanos </p>
                        </Nav.Link>
                        
                    </LinkContainer>
                    
                    <LinkContainer to='/shippingInfo'>
                        <Nav.Link>
                            <p>Envíos</p>
                        </Nav.Link>
                        
                    </LinkContainer>

                    {/* <LinkContainer to='/privacy'>
                        <Nav.Link>
                            <p>Aviso de Privacidad</p>
                        </Nav.Link>
                        
                    </LinkContainer>
                    <LinkContainer to='/termsandconditions'>
                        <Nav.Link>
                            <p>Términos y Condiciones</p>
                        </Nav.Link>
                        
                    </LinkContainer>
                    */}
                
                </Col>
                <Col sm={12} lg={4}>
                    <p>Siguenos</p>
                    <div className="d-inline-flex">
                        <div>                    
                            <a href='https://www.instagram.com/vanilla.d_/' target='_blank' rel="noreferrer">
                                <FaInstagram
                                    size='2em'
                                    color='pink'
                                />
                            </a>
                        </div>
                        <div className='px-3'>
                            <a href='https://www.facebook.com/profile.php?id=61555617325287' target='_blank' rel="noreferrer">
                                <FaFacebook
                                    size='2em'
                                    color='pink'
                                />
                            </a>
                        </div>
                        <div className=''>
                            <a href='https://www.tiktok.com/@vanilla.d_' target='_blank' rel="noreferrer">
                                <FaTiktok
                                    size='2em'
                                    color='pink'
                                />
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
  )
}

export default Footer