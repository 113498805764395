import { Link} from 'react-router-dom'
import { Carousel, Image } from 'react-bootstrap'
// import Loader from './Loader'
import Message from './Message'
import { useGetTopProductsQuery } from '../slices/productsApiSlice'

const ProductCarousel = () => {

    const { data: products, error, isLoading } = useGetTopProductsQuery()


    return isLoading ? console.log('') : error ? <Message variant='danger'>{error}</Message> 
    : (
            <Carousel pause='hover' className='bg-dark'>
                {products.map(product => (
                    <Carousel.Item key={product._id}>
                        <Link to={`/product/${product._id}`}>
                            <Image src={product.images[0]} alt={product.name} fluid />
                            <Carousel.Caption className='carousel-caption'>
                                <h2>{product.name} (${product.price})</h2>
                            </Carousel.Caption>
                        </Link>
                    </Carousel.Item>
                ))}
            </Carousel>
    )
}

export default ProductCarousel