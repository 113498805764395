import {useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom';
import {Form, Row, Col, Image, ListGroup, Card, Button, Badge} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Rating from '../components/Rating';
import { useGetProductDetailsQuery, useCreateReviewMutation } from "../slices/productsApiSlice";
import Loader from '../components/Loader';
import Message from "../components/Message";
import {addToCart} from '../slices/cartSlice';
import {toast} from 'react-toastify';
import Meta from '../components/Meta';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FaCheck } from 'react-icons/fa';

const ProductScreen = () => {

    const { id: productId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [qty, setQty] = useState(1);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [personalizedText, setPersonalizedText] = useState('');
    const [selectedColor, setSelectedColor] = useState(null); // State for selected color of Product
    const [pSelectedColor, setPSelectedColor] = useState(null); // State for selected color of Personalized Product

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
    };

    const { data: product, isLoading, error, refetch} = useGetProductDetailsQuery(productId);

    const [createReview, {isLoading: loadingProductReview}] = useCreateReviewMutation();

    const {userInfo} = useSelector((state) => state.auth);

    const addToCartHandler = () => {
        dispatch(addToCart({...product, qty, personalizedText, productColor: selectedColor, personalizedColor: pSelectedColor}));
        navigate('/cart');
    };

    const handleColorSelection = (color) => {
        setSelectedColor(color);
    };

    const handlePersonalizedColorSelection = (color) => {
        setPSelectedColor(color);
    };



    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await createReview({productId, rating, comment}).unwrap();
            toast.success('Review Submitted');
            setRating(0);
            setComment('');
            refetch();
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    return (
        <>
            <Link className='btn btn-light my-3' to='/'>
                Regresar
            </Link>

            {isLoading ? (
                <Loader/>
            ) : error ? (
                <Message variant='danger'>{error?.data?.message || error.error}</Message>
            ) : (
                <>
                <Meta title={product.name}/>
                 <Row>
                 <Col md={5}>

                    <div className='image-container'>
                        <Image src={`../${product.images[currentImageIndex]}`} alt={product.name} fluid/>
                        {product.images.length > 1 && (
                        <div className="image-controls2">
                            <button className="arrow-btn " onClick={prevImage}>
                                <MdKeyboardArrowLeft />
                            </button>
                            <button className="arrow-btn " onClick={nextImage}>
                                <MdKeyboardArrowRight />
                            </button>
                        </div>
                    )}
                    </div>
                     
                 </Col>
                 <Col md={4}>
                     <ListGroup variant='flush'>
                         <ListGroup.Item>
                             <h3>{product.name}</h3>
                         </ListGroup.Item>
                         <ListGroup.Item>
                             <Rating value={product.rating} text={`${product.numReviews} reviews`}/>
                         </ListGroup.Item>
                         <ListGroup.Item>
                             Precio: ${product.price}
                         </ListGroup.Item>
                         { product.personalized ? (<ListGroup.Item>
                                Personalizado: {product.personalizedDescription}
                                <Form.Control
                                    type='text'
                                    
                                    onChange={(e) => setPersonalizedText(e.target.value)}
                                >

                                </Form.Control>
                         </ListGroup.Item>)
                            : null
                         }

                        {product.personalizedColors ? (
                            <ListGroup.Item>
                                Color del personalizado
                                <div style={{display: 'flex'}}>
                                    {product.personalizedColors.map((personalizedColor, index) => (
                                        <div key={index} className="d-flex align-items-center" style={{ marginRight: '5px' }}>
                                            <Badge
                                                pill
                                                style={{
                                                    backgroundColor: personalizedColor,
                                                    cursor: 'pointer',
                                                    // marginRight: '5px',
                                                    border: '1px solid #ccc'
                                                }}
                                                color={personalizedColor}
                                                bg={personalizedColor}
                                                onClick={() => handlePersonalizedColorSelection(personalizedColor)}
                                            >
                                                V
                                                {pSelectedColor === personalizedColor && <FaCheck/>}
                                            </Badge>
                                        </div>
                                    ))}
                                </div>
                            </ListGroup.Item>
                        ) : null}

                        {product.productColor ? (
                            <ListGroup.Item>
                                Color de producto
                                <div style={{display: 'flex'}}>
                                    {product.productColors.map((productColor, index) => (
                                        <div key={index} className="d-flex align-items-center" style={{ marginRight: '5px' }}>
                                            <Badge
                                                pill
                                                style={{
                                                    backgroundColor: productColor,
                                                    cursor: 'pointer',
                                                    // marginRight: '5px',
                                                    border: '1px solid #ccc'
                                                }}
                                                color={productColor}
                                                bg={productColor}
                                                onClick={() => handleColorSelection(productColor)}
                                            >
                                                V
                                                {selectedColor === productColor && <FaCheck/>}
                                            </Badge>
                                        </div>
                                    ))}
                                </div>
                            </ListGroup.Item>
                        ) : null}

                         <ListGroup.Item>
                            Descripción : {product.description}
                         </ListGroup.Item>
                     </ListGroup>
                 </Col>
                 <Col md={3}>
                     <Card>
                         <ListGroup variant='flush'>
                             <ListGroup.Item>
                                 <Row>
                                     <Col>
                                         Precio:
                                     </Col>
                                     <Col>
                                         <strong>${product.price}</strong>
                                     </Col>
                                 </Row>
                             </ListGroup.Item>
                             <ListGroup.Item>
                                 <Row>
                                     <Col>
                                         Status:
                                     </Col>
                                     <Col>
                                         <strong>{product.countInStock > 0 ? 'En existencia' : 'Fuera de existencia'}</strong>
                                     </Col>
                                 </Row>
                             </ListGroup.Item>

                             {product.countInStock > 0 && (
                                <ListGroup.Item>
                                    <Row>
                                        <Col>Cantidad</Col>
                                        <Col>
                                            <Form.Control
                                                as='select'
                                                value={qty}
                                                onChange={(e) => setQty(Number(e.target.value))}>
                                                    {[...Array(product.countInStock).keys()].map((x) => (
                                                        <option key={x + 1} value={x + 1}>
                                                            {x + 1}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                             )}
                             <ListGroup.Item>
                                 <Button 
                                    className='btn-block' 
                                    type='button' 
                                    disabled={product.countInStock === 0}
                                    onClick={addToCartHandler}
                                    >
                                     Agregar a carrito
                                 </Button>
                             </ListGroup.Item>
                         </ListGroup>
                     </Card>
                 </Col>
             </Row>
             <Row className='review'>
                    <Col md={6}>
                        <h2>Reviews</h2>
                        {product.reviews.length === 0 && <Message>No Reviews</Message>}
                        <ListGroup variant='flush'>
                            {product.reviews.map((review) => (
                                <ListGroup.Item key={review._id}>
                                    <strong>{review.name}</strong>
                                    <Rating value={review.rating}/>
                                    <p>{review.createdAt.substring(0, 10)}</p>
                                    <p>{review.comment}</p>
                                </ListGroup.Item>
                            ))}

                            <ListGroup.Item>
                                <h2>Agrega un review</h2>
                                {loadingProductReview && <Loader/>}
                                {userInfo ? (
                                    <Form onSubmit={submitHandler}>
                                        <Form.Group controlId='rating' className='my-2'>
                                            <Form.Label>Rating</Form.Label>
                                            <Form.Control
                                                as='select'
                                                value={rating}
                                                onChange={(e) => setRating(Number(e.target.value))}>
                                                    <option value=''>Select...</option>
                                                    <option value='1'>1 - Poor</option>
                                                    <option value='2'>2 - Fair</option>
                                                    <option value='3'>3 - Good</option>
                                                    <option value='4'>4 - Very Good</option>
                                                    <option value='5'>5 - Excellent</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId='comment' className='my-2'>
                                            <Form.Label>Comentario</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                row='3'
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}>
                                            </Form.Control>
                                        </Form.Group>
                                        <Button disabled={loadingProductReview} type='submit' variant='primary'>Submit</Button>
                                    </Form>
                                ) : (
                                    <Message>Por favor <Link to='/login'>ingresa a tu cuenta</Link>para escribir un review</Message>
                                )}
                            </ListGroup.Item>
                        </ListGroup>

                    </Col>
             </Row>
             </>
            )}
           
        </>
    )
}

export default ProductScreen