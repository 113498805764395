import {Link, useParams} from 'react-router-dom'
import {Row, Col, ListGroup, Image, Card, Button, Badge} from 'react-bootstrap'
import {PayPalButtons, usePayPalScriptReducer} from '@paypal/react-paypal-js'
import { useSelector} from 'react-redux'
import Message from '../components/Message'
import {useEffect} from 'react'
import {useGetOrderDetailsQuery, usePayOrderMutation, useGetPayPalClientIdQuery, useDeliverOrderMutation} from '../slices/ordersApiSlice'
import Loader from '../components/Loader'
import {toast} from 'react-toastify'


const OrderScreen = () => {
    const {id: orderId} = useParams()

    const {data: order, refetch, isLoading, error} = useGetOrderDetailsQuery(orderId)

    const [payOrder, {isLoading:loadingPay}] = usePayOrderMutation()

    const [deliverOrder, {isLoading: loadingDeliver}] = useDeliverOrderMutation()

    const [{isPending}, paypalDispatch] = usePayPalScriptReducer();

    const {data: paypal, isLoading: loadingPayPal, error: errorPayPal} = useGetPayPalClientIdQuery();

    const {userInfo} = useSelector((state) => state.auth)

    useEffect(() => {
        if (!errorPayPal && !loadingPayPal && paypal.clientId) {
          const loadPaypalScript = async () => {
            paypalDispatch({
              type: 'resetOptions',
              value: {
                'clientId': paypal.clientId,
                currency: 'MXN',
              },
            });
            paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
          };
          if (order && !order.isPaid) {
            
              loadPaypalScript();
            
          }
        }
      }, [errorPayPal, loadingPayPal, order, paypal, paypalDispatch]);
    

    function onApprove(data, actions){
        return actions.order.capture().then(async function (details){
            try{
                await payOrder({orderId, details}).unwrap()
                refetch();
                toast.success('Payment successful')
            }catch(err){
                toast.error(err?.data?.message || err.message)
            }
        })
    }

    // TESTING ONLY!
    // async function onApproveTest(){
    //     await payOrder({orderId, details: {payer: {}}})
    //     refetch();
    //     toast.success('Payment successful')
    // } 

    function onError(err){
        toast.error(err.message)
    }

    function createOrder(data, actions){
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: order.totalPrice
                    }
                }
            ]
        }).then((orderId) => { 
            return orderId
        })
    }

    const deliverOrderHandler = async () => {
        try{
            await deliverOrder(orderId)
            refetch();
            toast.success('Order delivered')
        }catch(err){
            toast.error(err?.data?.message || err.message)
        }
    }


    return isLoading ? <Loader /> : error ? <Message variant='danger'>{error?.data?.message || error.error}</Message> : (
        <>
            <h1>Número de Orden {order._id}</h1>
            <Row>
                <Col md={8}>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Envío </h2>
                            <p>
                                <strong>Nombre : </strong>
                                {order.user.name}
                            </p>
                            <p>
                                <strong>Email: </strong>
                                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                            </p>
                            <p>
                                <strong>Domicilio : </strong>
                                {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                                {order.shippingAddress.postalCode},{' '}
                                {order.shippingAddress.country}
                            </p>
                            {order.isDelivered ? (
                                <Message variant='success'>
                                    Entregado en {order.deliveredAt}
                                </Message>
                            ) : (
                                <Message variant='danger'>No entregado</Message>
                            )}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2>Método de Pago</h2>
                            <p>
                                <strong>Método:  </strong>
                                {order.paymentMethod}
                            </p>
                            {order.isPaid ? (
                                <Message variant='success'>Pagado en {order.paidAt}</Message>
                            ) : (
                                <Message variant='danger'>No pagado</Message>
                            )}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2>Orden</h2>
                            {order.orderItems.length === 0 ? (
                                <Message>La orden está vacía</Message>
                            ) : (
                                <ListGroup variant='flush'>
                                    {order.orderItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                            
                                                <Col md={1}>
                                                    <Image
                                                        src={item.image}
                                                        alt={item.name}
                                                        fluid
                                                        rounded
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link to={`/product/${item.product}`}>
                                                        {item.name}
                                                    </Link>
                                                </Col>
                                                <Col>
                                                    {item.personalizedText ? 
                                                        <p>Texto personalizado:'{item.personalizedText}'</p> : null
                                                    }
                                                </Col>
                                                <Col>
                                                    {item.productColor ? 
                                                        <p>Color de producto: 
                                                            <Badge
                                                                pill
                                                                style={{
                                                                    backgroundColor: item.productColor,
                                                                    cursor: 'pointer',
                                                                    // marginRight: '5px',
                                                                    border: '1px solid #ccc'
                                                                }}
                                                                bg={item.productColor}
                                                            >
                                                                V
                                                            </Badge>
                                                        </p> : null
                                                    }
                                                </Col>
                                                <Col>
                                                    {item.personalizedColor ? 
                                                        <p>Color de personalizado: 
                                                            <Badge
                                                                pill
                                                                style={{
                                                                    backgroundColor: item.personalizedColor,
                                                                    cursor: 'pointer',
                                                                    // marginRight: '5px',
                                                                    border: '1px solid #ccc'
                                                                }}
                                                                bg={item.personalizedColor}
                                                            >
                                                                V
                                                            </Badge>
                                                        </p> : null
                                                    }
                                                </Col>
                                                
                                                <Col md={4}>
                                                    {item.qty} x ${item.price} = $
                                                    {item.qty * item.price}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>Resumen de pedido</h2>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>Artículos</Col>
                                    <Col>${order.itemsPrice}</Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>Envío </Col>
                                    <Col>${order.shippingPrice}</Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>Taxas </Col>
                                    <Col>${order.taxPrice}</Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>Total</Col>
                                    <Col>${order.totalPrice} MXN</Col>
                                </Row>
                            </ListGroup.Item>


                            {!order.isPaid && (
                                <ListGroup.Item>
                                    {loadingPay && <Loader />}
                                    {isPending ? <Loader /> : (
                                        <div>
                                            {/* <Button onClick={onApproveTest}
                                                    style={{marginBottom: '10px'}}>
                                                        Test Pay Order
                                            </Button> */}
                                            <PayPalButtons
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                                onError={onError}
                                                ></PayPalButtons>
                                        </div>
                                    )}
                                </ListGroup.Item>
                            )}

                            {loadingDeliver && <Loader />}
                            {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                                <ListGroup.Item>
                                    <Button
                                        type='button'
                                        className='btn btn-block'
                                        onClick={deliverOrderHandler}
                                    >
                                        Marcar como entregado 
                                    </Button>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default OrderScreen