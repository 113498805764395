import { Container } from "react-bootstrap"

const ShippingInfoScreen = () => {
  return (

    <Container>
      <p>
      <h2>
        Política de envío
      </h2>
    
    
      <br/>
      
      <b>INFORMACIÓN GENERAL</b>
      <br/>
      
      Tu pedido será enviado de 3 a 5 días hábiles, si tu pedido requiere personalización será enviado después de 8 días hábiles a través de Estafeta. 
      
      <br/>
      <b>COSTOS DE ENVÍO</b>
      
      <br/>
      
      <span>
        <strong>A todo México y Estados Unidos</strong>
        -
        <b>$120 MXN</b>
      </span>

      <br/>
      
      Si vives en una zona extendida, te notificaremos vía email para saber si prefieres pasar a recoger tu pedido a una sucursal cercana de Estafeta.
      
      <br/>

      <b>
        RASTREO DE TU PEDIDO
      </b>
      
      <br/>
      
      
      Una vez que tu pedido sea enviado, recibirás un correo con tu número de guía para que puedas rastrearlo.
      
      <br/>
      
      <b>
        CONTACTANOS
      </b>
      <br/>
      
      Para cualquier duda, escríbenos un correo a vanilladesigns.13@gmail.com. Nuestro horario de atención es de Lunes a Viernes de 10:00 am a 6:00 pm.
    
    </p>
    </Container>
    
  )
}

export default ShippingInfoScreen