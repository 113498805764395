import {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {toast} from 'react-toastify'
import {
    useGetProductDetailsQuery, 
    useUpdateProductMutation,
    useUploadProductImageMutation
} from '../../slices/productsApiSlice'
import { set } from 'mongoose'

const ProductEditScreen = () => {
    const {id: productId} = useParams()
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [images, setImages] = useState([])
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [description, setDescription] = useState('')
    const [personalized, setPersonalized] = useState(false)
    const [personalizedText, setPersonalizedText] = useState('')
    const [personalizedDescription, setPersonalizedDescription] = useState('')
    const [productColor, setProductColor] = useState(false)
    const [productColors, setProductColors] = useState([]); // Initialize productColors as an empty array
    const [numProductColors, setNumProductColors] = useState(0)
    const [personalizedColor, setPersonalizedColor] = useState(false)
    const [personalizedColors, setPersonalizedColors] = useState([]); // Initialize productColors as an empty array
    const [numPersonalizedColors, setNumPersonalizedColors] = useState(0)

    const {data: product, isLoading, refetch, error} = useGetProductDetailsQuery(productId)

    const [updateProduct, {isLoading: loadingUpdate}] = useUpdateProductMutation()

    const [uploadProductImage, {isLoading: loadingUpload}] = useUploadProductImageMutation()

    const navigate = useNavigate()

    useEffect(() => {
        if(product){
            setName(product.name)
            setPrice(product.price)
            setImages(product.images)
            setBrand(product.brand)
            setCategory(product.category)
            setCountInStock(product.countInStock)
            setDescription(product.description)
            setPersonalized(product.personalized)
            setPersonalizedDescription(product.personalizedDescription)
            setProductColor(product.productColor)
            setNumProductColors(product.numProductColors); // Set the initial value of numProductColors
            setProductColors(product.productColors);
            setPersonalizedColor(product.personalizedColor);
            setPersonalizedColors(product.personalizedColors);
            setNumPersonalizedColors(product.numPersonalizedColors);
            
        }
    } , [product])

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
        await updateProduct({
            productId,
            name,
            price,
            images,
            brand,
            category,
            description,
            countInStock,
            personalized,
            personalizedDescription,
            productColor,
            numProductColors, // Include numProductColors in the update
            productColors, // Include productColors in the update
            personalizedColors,
            personalizedColor,
            numPersonalizedColors
        }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
        toast.success('Product updated');
        refetch();
        navigate('/admin/productlist');
        } catch (err) {
        toast.error(err?.data?.message || err.error);
        }
    }

    const uploadFileHandler = async (e) => {
        const formData = new FormData()

        // formData.append('image', e.target.files[0])   //This was before for a single image

        for (let i = 0; i < e.target.files.length; i++) {
          formData.append('images', e.target.files[i]); // Change to append images
      }
        try {
            const res = await uploadProductImage(formData).unwrap()
            toast.success(res.message)
            // setImage(res.image)
            setImages(res.images || []); // Set default to empty array
        } catch (err) {
            toast.error(err?.data?.message || err.error)
        }
    }

    const handleProductColorChange = (index, value) => {
        const newColors = [...productColors]; // Create a copy of the current array
        newColors[index] = value; // Update the value at the specified index
        setProductColors(newColors); // Update the state with the new array
    };

    const handlePersonalizedColorChange = (index, value) => {
      const newColors = [...personalizedColors]; // Create a copy of the current array
      newColors[index] = value; // Update the value at the specified index
      setPersonalizedColors(newColors); // Update the state with the new array
  };

    const renderColorInputs = () => {
      return Array.from({ length: numProductColors }, (_, index) => (
          <Form.Group key={index}>
              <Form.Label>{`Product Color ${index + 1}`}</Form.Label>
              <Form.Control
                  type='text'
                  placeholder={`Enter product color ${index + 1}`}
                  value={productColors[index]} // Set the value based on the index
                  onChange={(e) => handleProductColorChange(index, e.target.value)} // Pass the index to handleProductColorChange
              />
          </Form.Group>
      ));
  };


  const renderPersonalizedColorInputs = () => {
    return Array.from({ length: numPersonalizedColors }, (_, index) => (
        <Form.Group key={index}>
            <Form.Label>{`Personalized Color ${index + 1}`}</Form.Label>
            <Form.Control
                type='text'
                placeholder={`Enter personalized color ${index + 1}`}
                value={personalizedColors[index]} // Set the value based on the index
                onChange={(e) => handlePersonalizedColorChange(index, e.target.value)} // Pass the index to handleProductColorChange
            />
        </Form.Group>
    ));
};

    return (
        <>
       
        <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='price'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter price'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Image</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                value={images}
                onChange={(e) => setImages}
              ></Form.Control>
              <Form.Control
                label='Choose Files'
                onChange={uploadFileHandler}
                type='file'
                multiple
              ></Form.Control>
              {loadingUpload && <Loader />}
            </Form.Group>

            <Form.Group controlId='brand'>
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter brand'
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='countInStock'>
              <Form.Label>Count In Stock</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter countInStock'
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='category'>
              <Form.Label>Category</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter category'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='personalized'>
              <Form.Label>Personalized</Form.Label>
              <Form.Check
                type='checkbox'
                checked={personalized}
                onChange={(e) => setPersonalized(e.target.checked)}
                />
            </Form.Group>

            {personalized && (
              <Form.Group controlId='personalizedDescription'>
                <Form.Label>Personalized Description</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter personalized description'
                  value={personalizedDescription}
                  onChange={(e) => setPersonalizedDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>
            )}

            <Form.Group controlId='personalized'>
                <Form.Label>Different Color Products</Form.Label>
                <Form.Check
                    type='checkbox'
                    checked={productColor}
                    onChange={(e) => setProductColor(e.target.checked)}
                />
            </Form.Group>

            {product && productColor && (
              <>
                  <Form.Group controlId='productColor'>
                      <Form.Label>Number of Different Product Colors</Form.Label>
                      <Form.Control
                          type='number'
                          placeholder='Enter number of different colors'
                          value={numProductColors}
                          onChange={(e) => setNumProductColors(e.target.value)}
                      />
                  </Form.Group>
                  {renderColorInputs()} {/* Render color inputs based on numProductColors */}
              </>
          )}

          <Form.Group controlId='personalized'>
              <Form.Label>Different Personalized Colors</Form.Label>
              <Form.Check
                  type='checkbox'
                  checked={personalizedColor}
                  onChange={(e) => setPersonalizedColor(e.target.checked)}
              />
          </Form.Group>

          {product && personalizedColor && (
              <>
                  <Form.Group controlId='personalizedColor'>
                      <Form.Label>Number of Different Personalized Colors</Form.Label>
                      <Form.Control
                          type='number'
                          placeholder='Enter number of different colors'
                          value={numPersonalizedColors}
                          onChange={(e) => setNumPersonalizedColors(e.target.value)}
                      />
                  </Form.Group>
                  {renderPersonalizedColorInputs()} {/* Render color inputs based on numProductColors */}
              </>
          )}

            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: '1rem' }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
    )
}

export default ProductEditScreen